import Api from '../api'

export default {
    bulkImport(model) {
        return Api.post('/EivApi/BulkImport', JSON.stringify(model))
    },
    bulkExtend(model) {
        return Api.post('/EivApi/BulkExtend', JSON.stringify(model))
    },
    bulkRenew(model) {
        return Api.post('/EivApi/BulkRenew', JSON.stringify(model))
    },
    getEivInitializationDataModel(userId, langCode) {
        return Api.get(`/EivApi/GetEivInitializationDataModel?userId=${userId}&langCode=${langCode}`)
    },
    createEiv(model, languageCode) {
        return Api.post(`/EivApi/CreateEiv?languageCode=${languageCode}`, JSON.stringify(model))
    },
    initilizationSubmitPage(eivId) {
        window.location = window.baseUrl + `/Eiv/InitializationSubmit?userVerifiedId=${eivId}`
    },
    getUserHasAccessToBulkUploadPackages(userId) {
        return Api.get(`/EivApi/UserHasAccessToBulkUploadPackages?userId=${userId}`)
    },
    userCanAccessBulkExtend() {
        return Api.get(`/EivApi/UserCanAccessBulkExtend`)
    },
    userCanAccessBulkRenew() {
        return Api.get(`/EivApi/UserCanAccessBulkRenew`)
    },
    getUserOnlineFormsAccess() {
        return Api.get(`/EivApi/GetUserOnlineFormsAccess`)
    },
    /** Pending Processing Page - Emailed Requests */
    getEmailedRequests() {
        return Api.get(`/EivApi/GetEmailedRequests`)
    },
    deleteEmailedRequest(emailRequestId) {
        return Api.post(`/EivApi/DeleteEmailRequest`, { emailRequestId: emailRequestId })
    },
    async createTransactionFromEmailedRequest(emailRequestId) {
        const response = await Api.post(`/EivApi/CreateServiceRequest`, { emailRequestId: emailRequestId })
        // Sending the user to the BuildRequest url, with the stagingId in the parameters.
        window.location = window.baseUrl + response.data
    },

    getTrustStampStatus(userVerifiedId) {
        return Api.get(`/EivApi/GetTrustStampStatus?userVerifiedId=${userVerifiedId}`)
    },

    redirectToEivStep(url) {
        window.location = window.baseUrl + `/${url}`;
    },

/** Eiv Sessions/Expiry */
    getCandidateExpiryStatus(eivId) {
        return Api.post(`/EivApi/GetCandidateExpiryStatus`, { eivId: eivId })
    },

    updateCandidateExpiryTime(eivId) {
        return Api.post('/EivApi/ForceUpdateExpiry', { eivId: eivId })
    },

/** Candidate-Provided Vulnerable Sector Check **/
    getEivForCandidateUpload(eivId) {
        return Api.get(`/EivApi/IsEivValidForCandidateUpload?requestId=${eivId}`)
    },
    uploadCandidateProvidedVSCheck(eivId, file) {
        return Api.post(`/EivApi/UploadCandidateProvidedVSDocument?requestId=${eivId}`, file)
    },
/** Email Poller Status **/
    GetNextProcessingTime() {
        return Api.get(`/EivApi/GetNextProcessingTime`)
    },
}