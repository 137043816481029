/* eslint-disable @typescript-eslint/no-explicit-any */
// This is the "store accessor":
// It initializes all the modules using a Vuex plugin (see store/index.ts)
// In here you import all your modules, call getModule on them to turn them
// into the actual stores, and then re-export them.

import { FeatureStore } from './features/feature-store'
import { FeatureEnableListStore } from './private/feature-enable-list-store'

import { ClientStore } from './private/client-store'
import { ContactStore } from './private/contact-store'
import { ContentStore } from './private/content-store'

import { PublicStore } from './public/public-store'

import { ResourceStore } from './resources/resource-store'

import { BreadStore } from './sections/bread-store'
import { HeaderBarStore } from './sections/header-bar-store'
import { SideBarStore } from './sections/side-bar-store'

import { SessionStore } from './session/session-store'
import { EivSessionStore } from './public/eiv/session/eiv-session-store'

import { ErrorLogStore } from './private/error-log-store'
import { PageAuditStore } from './private/page-audit-store'

import { EmailedRequestsStore } from './private/emailed-requests-store'
import { EmailTemplatesStore } from './private/email-templates-store'

import { UploadPortalStore } from './public/eiv/portal/upload-portal-store'

export const modules = {
    bread: BreadStore,
    content: ContentStore,
    feature: FeatureStore,
    featureEnableList: FeatureEnableListStore,
    headerbar: HeaderBarStore,
    sidebar: SideBarStore,
    public: PublicStore,
    resource: ResourceStore,
    session: SessionStore,
    eivSession: EivSessionStore,

    errorLog: ErrorLogStore,
    client: ClientStore,
    contact: ContactStore,
    pageAudit: PageAuditStore,
    emailedRequest: EmailedRequestsStore,
    emailTemplates: EmailTemplatesStore,
    uploadPortal: UploadPortalStore
};
