import Api from '../api'

export default {
    getSimpleContacts() {
        return Api.get('/AccountApi/GetSimpleContacts?includeInactiveUsers=false')
    },
    getClients() {
        return Api.get('/AccountApi/GetClients')
    },
    getUsers(contactId) {
        return Api.get(`/AccountApi/GetUsers?contactId=${contactId}`)
    },
    getClientPackages(contactId) {
        return Api.get(`/AccountApi/GetClientPackages?contactId=${contactId}`)
    },
    getContact(id) {
        return Api.get(`/AccountApi/GetContact/${id}`)
    },

    updateContact(id, attributes) {
        return Api.put(`/AccountApi/UpdateContact/${id}`, JSON.stringify(attributes))
    },

    activate (id) {
        return Api.get(`/AccountApi/Activate/${id}`)
    },

    deactivate (id) {
        return Api.get(`/AccountApi/Deactivate/${id}`)
    },

    unlock (id) {
        return Api.get(`/AccountApi/Unlock/${id}`)
    },

    resetPassword (id) {
        return Api.get(`/AccountApi/ResetPassword/${id}`)
    },

    addUser (id) {
        window.location = window.baseUrl + `/Account/EditUser/${id}?isNewUser=True&newUserType=User`
    },

    addClient (id) {
        window.location = window.baseUrl + `/Account/EditUser/${id}?isNewUser=True&newUserType=Client`
    },

    copyUser (id) {
        window.location = window.baseUrl + `/Account/EditUser/${id}?isNewUser=True&newUserType=User&copyFromId=${id}`
    },

    copyClient (id) {
        window.location = window.baseUrl + `/Account/EditUser/${id}?isNewUser=True&newUserType=Client&copyFromId=${id}`
    },

    editUser (id) {
        window.location = window.baseUrl + `/Account/EditUser/${id}?isNewUser=False`
    },

    editClient (id) {
        window.location = window.baseUrl + `/Account/EditUser/${id}?isNewUser=False`
    }
}