import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'intersection-observer'
import '../create-nonce';

import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en' // locale-data for en

import '@formatjs/intl-datetimeformat/polyfill'
import '@formatjs/intl-datetimeformat/locale-data/en' // locale-data for en
import '@formatjs/intl-datetimeformat/add-all-tz' // Add ALL tz data

import Vue from 'vue'

/** STYLING FRAMEWORK **/
import {
    AlertPlugin,
    BButton,
    BadgePlugin,
    ModalPlugin,
    ToastPlugin,
    CardPlugin,
    CollapsePlugin,
    DropdownPlugin,
    FormPlugin,
    FormGroupPlugin,
    FormCheckboxPlugin,
    FormTextareaPlugin,
    FormSelectPlugin,
    FormInputPlugin,
    FormFilePlugin,
    FormRadioPlugin,
    ImagePlugin,
    LayoutPlugin,
    LinkPlugin,
    ListGroupPlugin,
    PaginationPlugin,
    ProgressPlugin,
    TablePlugin,
    BPopover,
    TabsPlugin,
    TooltipPlugin
} from 'bootstrap-vue'

/** CUSTOM STYLING **/
import './assets/custom.scss'

/** ICON LIBRARIES **/
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowRight,
    faBook,
    faCheckSquare,
    faEnvelopeOpenText,
    faFolder,
    faFolderPlus,
    faFolderOpen,
    faForward,
    faTools,
    faPlug,
    faUser,
    faUserPlus,
    faUserSecret,
    faCopy,
    faUnlock,
    faBan,
    faKey,
    faEllipsisV,
    faMinusSquare,
    faPlusSquare,
    faLink,
    faSave,
    faExternalLink,
    faCheck,
    faStar,
    faFileAlt,
    faExclamation,
    faQuestionCircle,
    faSearch,
    faSync,
    faDownload,
    faCheckCircle,
    faCircle,
    faBrowser,
    faPlus,
    faEdit,
    faTrashAlt,
    faGripLines,
    faEyeSlash,
    faExclamationTriangle,
    faChevronDoubleUp,
    faChevronDoubleDown,
    faInfoSquare
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/** THIRD PARTY VUE HELPERS **/
import Vuelidate from 'vuelidate'
import vuejsDatepicker from 'vuejs-datepicker'
import VueTheMask from 'vue-the-mask'
import vueDebounce from 'vue-debounce'
import vSelect from 'vue-select'
import { sync } from 'vuex-router-sync'
import VCalendar from 'v-calendar'

/** BASE COMPONENTS, MIXINS AND RESOURCES **/
import App from './app'
import Mvc from './mvc'
import store from './stores/store'

import ViewHeader from './components/view-header'
import EmptyGroup from './components/empty-group/empty-group'
import DisabledView from './components/disabled-view'
//import JsonExcel from "./components/vue-json-excel/JsonExcel"

import i18n from './resources/i18n'
import FileDownloadPlugin from './plugins/file-download-plugin'
import './utilities/datetime-filters'
import './utilities/moment-ext'
import './utilities/is-ie'

import BaseMixin from './mixins/base-mixin'

import router from './router/router'

/** GLOBAL PLUGINS AND COMPONENTS **/

Vue.use(AlertPlugin)
Vue.use(BadgePlugin)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormRadioPlugin)
Vue.use(ImagePlugin)
Vue.use(LinkPlugin)
Vue.use(ListGroupPlugin)
Vue.use(PaginationPlugin)
Vue.use(ProgressPlugin)
Vue.use(TabsPlugin)
Vue.use(TablePlugin)
Vue.use(TooltipPlugin)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(vueDebounce)
Vue.use(VCalendar)

Vue.use(FileDownloadPlugin)

Vue.component('b-popover', BPopover)
Vue.component('b-button', BButton)
Vue.component('view-header', ViewHeader)
Vue.component('empty-group', EmptyGroup)
Vue.component('disabled-view', DisabledView)
Vue.component('v-icon', FontAwesomeIcon)
Vue.component('vuejs-datepicker', vuejsDatepicker)
Vue.component('v-select', vSelect)
//Vue.component("downloadExcel", JsonExcel);

Vue.mixin(BaseMixin)

config.autoAddCss = false

library.add(
    faArrowRight,
    faBook,
    faCheckSquare,
    faEnvelopeOpenText,
    faFolder,
    faFolderOpen,
    faFolderPlus,
    faForward,
    faTools,
    faPlug,
    faUser,
    faUserPlus,
    faUserSecret,
    faCopy,
    faUnlock,
    faBan,
    faKey,
    faEllipsisV,
    faMinusSquare,
    faPlusSquare,
    faLink,
    faSave,
    faExternalLink,
    faCheck,
    faStar,
    faSync,
    faFileAlt,
    faExclamation,
    faQuestionCircle,
    faSearch,
    faDownload,
    faCheckCircle,
    faCircle,
    faBrowser,
    faPlus,
    faEdit,
    faTrashAlt,
    faGripLines,
    faEyeSlash,
    faExclamationTriangle,
    faChevronDoubleUp,
    faChevronDoubleDown,
    faInfoSquare
)

if (router) {
    const unsync = sync(store, router, { moduleName: 'route' })

    new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount("#home")
}
else {
    new Vue({
        store,
        i18n,
        render: h => h(Mvc)
    }).$mount("#home")
}