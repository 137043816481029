import Api from '../api';

export default {
	getToken(eivId) {
		return Api.get(`/TrustStampApi/GetToken?eivId=${eivId}`);
	},

	addFile(eivId, fileData) {
		return Api.post(`/TrustStampApi/AddFile`, { eivId, fileData });
	},

	validateProofOfLiveness(eivId, jwt, mediaUrl, isImage) {
		return Api.post(`/TrustStampApi/ValidateProofOfLiveness`, { eivId, jwt, mediaUrl, isImage });
	},

	pollForResult(eivId, jwt, requestId) {
		return Api.post(`/TrustStampApi/PollForResult`, { eivId, jwt, requestId });
	},

	validateIdentification(eivId, jwt, mediaUrl, countryCode, stateCode) {
		return Api.post(`/TrustStampApi/ValidateIdentification`, { eivId, jwt, mediaUrl, countryCode, stateCode });
	},

	validateBarcode(eivId, jwt, mediaUrl) {
		return Api.post(`/TrustStampApi/ValidateBarcode`, { eivId, jwt, mediaUrl });
	}
}